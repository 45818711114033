<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <APITokensTable></APITokensTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import APITokensTable from "./tables/APITokensTable.vue";
export default {
  name: "APITokens",
  components: {
    APITokensTable,
  },
  data() {
    return {
      msg: "APITokens",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
